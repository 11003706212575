$default-gray-900: #161e2e;
$default-gray-800: #252f3f;
$default-gray-700: #374151;
$default-gray-600: #4b5563;
$default-gray-500: #6b7280;
$default-gray-400: #9fa6b2;
$default-gray-300: #d2d6dc;
$default-gray-200: #e5e7eb;
$default-gray-100: #f4f5f7;
$default-white: #fff;

.color-palette-default {
  --color-palette-gray-900: $default-gray-900;
  --color-palette-gray-800: $default-gray-800;
  --color-palette-gray-800-transparent: rgba(37, 47, 63, 0);
  --color-palette-gray-700: $default-gray-700;
  --color-palette-gray-700-transparent: #37415100;
  --color-palette-gray-600: $default-gray-600;
  --color-palette-gray-500: $default-gray-500;
  --color-palette-gray-400: $default-gray-400;
  --color-palette-gray-300: $default-gray-300;
  --color-palette-gray-200: $default-gray-200;
  --color-palette-gray-100: $default-gray-100;
  --color-palette-white: $default-white;
}

.color-palette-black {
  --color-palette-gray-900: #2d2d2d;
  --color-palette-gray-800: #000;
  --color-palette-gray-800-transparent: rgba(0, 0, 0, 0);
  --color-palette-gray-700: #252525;
  --color-palette-gray-700-transparent: #25252500;
  --color-palette-gray-600: #43434f;
  --color-palette-gray-500: #575757;
  --color-palette-gray-400: $default-gray-400;
  --color-palette-gray-300: $default-gray-300;
  --color-palette-gray-200: $default-gray-200;
  --color-palette-gray-100: $default-gray-100;
  --color-palette-white: #fff;
}

.color-palette-white {
  --color-palette-gray-900: #eeeeee;
  --color-palette-gray-800: #fff;
  --color-palette-gray-800-transparent: rgba(255, 255, 255, 0);
  --color-palette-gray-700: #e8e9ec;
  --color-palette-gray-700-transparent: #e8e9ec00;
  --color-palette-gray-600: #969999;
  --color-palette-gray-500: #5a5a5a;
  --color-palette-gray-400: #555961;
  --color-palette-gray-300: #383838;
  --color-palette-gray-200: #282a2c;
  --color-palette-gray-100: #2e353f;
  --color-palette-white: #252f3f;
}

@define-mixin palette-item $key {
  &.bg-$(key),
  .bg-$(key),
  .hover\:bg-$(key):hover,
  .focus\:bg-$(key):focus,
  .active\:bg-$(key):active {
    background-color: $default-$(key);
    background-color: var(--color-palette-$key);
  }
  &.bg-always-$(key),
  .bg-always-$(key),
  .hover\:bg-always-$(key):hover,
  .focus\:bg-always-$(key):focus,
  .active\:bg-always-$(key):active {
    background-color: $default-$(key);
  }

  .text-$(key),
  .hover\:text-$(key):hover,
  .focus\:text-$(key):focus,
  .active\:text-$(key):active,
  .group:hover .group-hover\:text-$(key) {
    color: $default-$(key);
    color: var(--color-palette-$key);
  }

  .text-always-$(key),
  .hover\:text-always-$(key):hover,
  .focus\:text-always-$(key):focus,
  .active\:text-always-$(key):active {
    color: $default-$(key);
  }

  .border-$(key) {
    border-color: $default-$(key);
    border-color: var(--color-palette-$key);
  }

  @media (min-width: 640px) {
    .sm\:text-$(key) {
      color: $default-$(key);
      color: var(--color-palette-$key);
    }
  }
}

body {
  @mixin palette-item gray-900 {
  }
  @mixin palette-item gray-800 {
  }
  @mixin palette-item gray-700 {
  }
  @mixin palette-item gray-600 {
  }
  @mixin palette-item gray-500 {
  }
  @mixin palette-item gray-400 {
  }
  @mixin palette-item gray-300 {
  }
  @mixin palette-item gray-200 {
  }
  @mixin palette-item gray-100 {
  }
  @mixin palette-item white {
  }
}
