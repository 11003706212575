#evPlayer .bmpui-ui-hugeplaybacktogglebutton:focus {
  box-shadow: none !important;
}

#evPlayer .bmpui-ui-watermark {
  display: none;
}

#evPlayer .bmpui-ui-airplaytogglebutton:focus,
#evPlayer .bmpui-ui-audiotracksettingstogglebutton:focus,
#evPlayer .bmpui-ui-button:focus,
#evPlayer .bmpui-ui-casttogglebutton:focus,
#evPlayer .bmpui-ui-clickoverlay:focus,
#evPlayer .bmpui-ui-closebutton:focus,
#evPlayer .bmpui-ui-fullscreentogglebutton:focus,
#evPlayer .bmpui-ui-hugeplaybacktogglebutton:focus,
#evPlayer .bmpui-ui-hugereplaybutton:focus,
#evPlayer .bmpui-ui-listbox .bmpui-ui-listbox-button:focus,
#evPlayer .bmpui-ui-piptogglebutton:focus,
#evPlayer .bmpui-ui-playbacktogglebutton:focus,
#evPlayer .bmpui-ui-settingspanelpagebackbutton:focus,
#evPlayer .bmpui-ui-settingspanelpageopenbutton:focus,
#evPlayer .bmpui-ui-settingstogglebutton:focus,
#evPlayer .bmpui-ui-button-ad-skip:focus,
#evPlayer .bmpui-ui-subtitlesettingsresetbutton:focus,
#evPlayer .bmpui-ui-subtitlesettingstogglebutton:focus,
#evPlayer .bmpui-ui-volumetogglebutton:focus,
#evPlayer .bmpui-ui-vrtogglebutton:focus,
#evPlayer .bmpui-ui-watermark:focus {
  box-shadow: none;
  outline: none;
  filter: drop-shadow(0px 0px 6px #fff);
}

#evPlayer .bmpui-ui-seekbar:focus,
#evPlayer .bmpui-ui-volumeslider:focus {
  box-shadow: none;
  outline: none;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
}

#evPlayer .bmpui-ui-selectbox:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  outline: none;
}

#evPlayer .bmpui-seekbar-playbackposition-marker {
  background-color: #1fabe2;
}

#evPlayer .bmpui-ui-settings-panel {
  z-index: 99999999;
}
